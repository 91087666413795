"use strict";
const { h, render, Component } = preact;
/** @jsx h */
document.body.firstChild.remove();

const jsonParse = text => {
  const dateFormat = /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}Z$/;
  function reviver(key, value) {
    if (typeof value === "string" && dateFormat.test(value)) {
      return new Date(value);
    }
    return value;
  }
  return JSON.parse(text, reviver);
};

const fixupTags = result => {
  if (result.t.length === 1 && result.t[0] === "") {
    result.t.shift();
  }
};

const FormatDate = ({ date }) => {
  const display = moment.utc(date).fromNow();
  const raw = date.toISOString();
  return <time datetime={raw} title={raw}>{display}</time>;
};

const Tags = ({ children }) => (
  <ul className="tags">
    {children.map(t => (
      <li> {t} </li> 
    ))}
  </ul>
);

const Card = ({ link }) => (
  <div>
    <div className="link">
      <a href={link.u} target="_blank" rel="noopener">{link.d}</a>
    </div>
    <div className="attribution">
      <FormatDate date={link.dt} /> by {link.a}
    </div>
    <Tags>{link.t}</Tags>
    <p className="description">{link.n}</p>
  </div>
);

class Demo extends Component {
  constructor(props) {
    super(props);
    this.setState({ loading: true });
  }
  async componentDidMount() {
    const contents = jsonParse(
      await fetch("popular.json").then(res => res.text())
    );
    contents.forEach(fixupTags);
    this.setState({ loading: false, contents });
  }
  render(props, { loading, contents }) {
    if (loading) {
      return <p>loading</p>;
    }
    return (
      <ul className="cards">
        {contents.map(x => (
          <li>
            <Card link={x} />
          </li>
        ))}
      </ul>
    );
  }
}

render(<Demo />, document.body);
